/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import { FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa"
import { Container, Col, Row } from "react-bootstrap"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>

      <footer>
        <Container>
          {/* <div className="d-flex justify-content-between"> */}
          <Row className="justify-content-between">
            <Col xs={{span: 12, order: 2}} sm={{span: 'auto', order: 1}}>
              <div style={{textAlign: 'center'}}>
                <small>Charles Wolfson Centre © {new Date().getFullYear()}, Built by {' '}
                  <a href="https://www.thedevdoctor.com" target="_blank" style={{
                    color: "#EF0E0E"
                  }} rel="noreferrer">Dev Doctor Ltd.</a></small>
              </div>
            </Col>

            <Col xs={{span: 12, order: 1}}  sm={{span: 'auto', order: 2}}>
              <div style={{textAlign: 'center', marginBottom: '0.5rem'}}>
                <a target="_blank" href="https://www.youtube.com/channel/UCKboX54yAOw2mrcBS_xxBtQ" rel="noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                  <FaYoutube size={24} className="social-logo" />
                </a>
                <a target="_blank" href="https://twitter.com/CWCReconSurgery" rel="noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                  <FaTwitter size={24} className="social-logo" />
                </a>
                <a target="_blank" href="https://www.instagram.com/thefreeplasticsurgery/" rel="noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                  <FaInstagram size={24} className="social-logo" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
