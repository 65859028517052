import * as React from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import { Navbar, Nav, Dropdown, Container, NavItem, NavLink } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { signUpButton, navItem, navItemActive, header, collapseToggle, collapseElements, userOption, dropdownToggle, navItemCont } from './header.module.scss'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Auth } from "aws-amplify";
import { IoPerson } from "react-icons/io5"

const Header = () => {

  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {

    if (authState === undefined) {
      Auth.currentAuthenticatedUser().then(authData => {
        setAuthState(AuthState.SignedIn);
        setUser(authData);
      }).catch(ex => { console.log(ex) });
    }

    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  const signOutUser = () => {
    Auth.signOut().then(() => {
      setUser(null)
      setAuthState(null)
      navigate('/')
    })
  }

  return (
    <header className={`${header}`}>
      <Container>
        <Navbar collapseOnSelect expand="lg" variant="dark" style={{ padding: 0 }}>
          <Navbar.Brand href="/" style={{ marginTop: '0.75rem' }}>
            <StaticImage
              src="../images/CW-logo-white.png"
              width={40}
              quality={40}
              formats={["AUTO", "WEBP"]}
              alt="Charles Wolfson Centre"
            />
          </Navbar.Brand>

          <span style={{ flex: 2 }}></span>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" className={`justify-content-end ${collapseToggle}`} />
          <Navbar.Collapse id="responsive-navbar-nav" className={`justify-content-end ${collapseElements}`}>
            <Nav as="ul" className="align-items-center justify-content-end">
              <Nav.Item as="li" className={navItemCont}>
                <Link to="/" className={navItem} activeClassName={navItemActive}>Home</Link>
              </Nav.Item>
              <Nav.Item as="li" className={navItemCont}>
                <Link to="/about/" className={navItem} activeClassName={navItemActive}>About</Link>
              </Nav.Item>
              <Nav.Item as="li" className={navItemCont}>
                <Link to="/research/" className={navItem} activeClassName={navItemActive}>Research</Link>
              </Nav.Item>
              <Nav.Item as="li" className={navItemCont}>
                <Link to="/education/" className={navItem} activeClassName={navItemActive}>Education</Link>
              </Nav.Item>
              {/* <Nav.Item as="li" className={navItemCont}>
                <Link to="/stories/" className={navItem} activeClassName={navItemActive}>Stories</Link>
              </Nav.Item> */}


              {/* <Button className={loginButton}>Login</Button> */}


            </Nav>
          </Navbar.Collapse>
          {authState === AuthState.SignedIn && user ?
            (
              
              <div className={userOption}>
                <Dropdown as={NavItem}>
                  <Dropdown.Toggle as={NavLink} className={dropdownToggle}>
                    {user.attributes.given_name}
                    {'   '}
                    <IoPerson />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => navigate('/account')}>Resources</Dropdown.Item>
                    <Dropdown.Item onClick={() => signOutUser()}>Sign Out</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

            )

            :

            (
              <Link to="/account/" className={signUpButton} style={{ textDecoration: 'none' }}>Sign In</Link>
            )}
        </Navbar>
      </Container>
    </header >
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
